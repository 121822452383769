<div class="card">
    <p-table 
        #tokensTable
        [value]="tokens" 
        [paginator]="tokens?.length > 0"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]"
        [loading]="loading"
        styleClass="p-datatable-striped"
        [tableStyle]="{'min-width': '50rem'}"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
        [globalFilterFields]="['purchasedAt', 'name', 'typeId', 'price']"
        selectionMode="single"
        [(selection)]="selectedToken"
        (onRowSelect)="openToken($event)">

        <ng-template pTemplate="caption">
            <div class="flex justify-content-between align-items-center">
                <h3 class="m-0">Entradas ({{ tokens?.length || 0 }})</h3>
                <div class="flex align-items-center gap-2">
                    <button
                        pButton
                        label="Borrar filtros"
                        class="p-button-outlined"
                        icon="pi pi-filter-slash"
                        (click)="clear(tokensTable)">
                    </button>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="tokensTable.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar entrada...">
                    </span>
                </div>
            </div>
        </ng-template>
        
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="purchasedAt">
                    Fecha <p-sortIcon field="purchasedAt"></p-sortIcon>
                    <p-columnFilter type="text" field="purchasedAt" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="name">
                    Nombre <p-sortIcon field="name"></p-sortIcon>
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="typeId">
                    Tipo <p-sortIcon field="typeId"></p-sortIcon>
                    <p-columnFilter type="text" field="typeId" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="price" style="text-align: right">
                    Valor <p-sortIcon field="price"></p-sortIcon>
                    <p-columnFilter type="numeric" field="price" display="menu"></p-columnFilter>
                </th>
                <th style="width: 3rem"></th>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="body" let-token>
            <tr [pSelectableRow]="token" class="cursor-pointer">
                <td>{{ token.purchasedAt | date : "dd/MM/yyyy HH:mm" }}</td>
                <td>{{ token.name }}</td>
                <td>{{ token.typeId }}</td>
                <td style="text-align: right">
                    {{ (token.price + token.upsellPrice) / 100 | currency : "EUR" : "symbol" : "1.2-2" }}
                </td>
                <td>
                    <i class="pi pi-angle-right"></i>
                </td>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">
                    <div class="empty-message">
                        <i class="pi pi-ticket"></i>
                        <h3>No hay entradas disponibles</h3>
                        <p>Cuando tengas entradas asignadas, aparecerán en este listado</p>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingbody">
            <tr *ngFor="let _ of [1,2,3,4,5]">
                <td><p-skeleton width="120px"></p-skeleton></td>
                <td><p-skeleton width="200px"></p-skeleton></td>
                <td><p-skeleton width="100px"></p-skeleton></td>
                <td style="text-align: right"><p-skeleton width="80px"></p-skeleton></td>
                <td style="width: 3rem">
                    <p-skeleton width="1rem" styleClass="ml-2"></p-skeleton>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
