<p-card>
  <ng-template pTemplate="title">
    <div class="flex align-items-center gap-2">
      <i class="fas fa-eye"></i>
      <span>Historial de interacciones</span>
    </div>
  </ng-template>
  <ng-template pTemplate="subtitle">Registro de interacciones del usuario con las comunicaciones</ng-template>

  <p-table #dt [value]="interactions" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} interacciones"
           [globalFilterFields]="['campaignName','channel','eventType']">
    
    <ng-template pTemplate="caption">
      <div class="header-content">
        <span class="p-input-icon-left">
          <i class="fas fa-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" 
                 placeholder="Buscar interacciones" />
        </span>
        <button pButton label="Limpiar" class="p-button-outlined" icon="fas fa-times" (click)="clear(dt)"></button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon></th>
        <th pSortableColumn="channel">Canal <p-sortIcon field="channel"></p-sortIcon></th>
        <th pSortableColumn="campaignName">Campaña <p-sortIcon field="campaignName"></p-sortIcon></th>
        <th pSortableColumn="eventType">Tipo <p-sortIcon field="eventType"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-interaction>
      <tr>
        <td>{{formatDate(interaction.date)}}</td>
        <td>
          <i class="channel-icon fas fa-envelope"></i>
          {{getChannelLabel(interaction.channel)}}
        </td>
        <td>{{interaction.campaignName}}</td>
        <td>
          <span class="interaction-type" [class]="getTypeClass(interaction.eventType)">
            {{getTypeLabel(interaction.eventType)}}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">
          <div class="empty-message">
            <i class="fas fa-inbox"></i>
            <h3>No hay interacciones registradas</h3>
            <p>Cuando el usuario interactúe con las comunicaciones, aparecerán en este listado</p>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let _ of [1,2,3,4,5]">
        <td><p-skeleton width="120px"></p-skeleton></td>
        <td>
          <div class="flex align-items-center">
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton width="100px"></p-skeleton>
          </div>
        </td>
        <td><p-skeleton width="200px"></p-skeleton></td>
        <td><p-skeleton width="100px" height="24px" styleClass="interaction-type"></p-skeleton></td>
      </tr>
    </ng-template>
  </p-table>
</p-card> 