<p-card>
  <ng-template pTemplate="title">
    <div class="flex align-items-center gap-2">
      <i class="fas fa-user-circle"></i>
      <span>Perfil público</span>
    </div>
  </ng-template>
  <ng-template pTemplate="subtitle">Datos públicos del fan</ng-template>

  <div class="grid">
    <!-- Headline -->
    <div class="col-12 field">
      <label class="block font-medium mb-2">
        <i class="fas fa-heading mr-2"></i>
        Headline
      </label>
      <span class="p-input-icon-left w-full">
        <i class="fas fa-user-tag"></i>
        <input type="text" pInputText [value]="user?.headline || 'No especificado'" 
               class="w-full" [disabled]="true"/>
      </span>
    </div>

    <!-- Bio -->
    <div class="col-12 field">
      <label class="block font-medium mb-2">
        <i class="fas fa-book-open mr-2"></i>
        Biografía
      </label>
      <textarea pInputTextarea [value]="user?.description || 'No especificada'" 
                class="w-full" [disabled]="true" [rows]="4"></textarea>
    </div>

    <!-- Social Media Section -->
    <div class="col-12">
      <label class="block font-medium mb-3">
        <i class="fas fa-share-alt mr-2"></i>
        Redes Sociales
      </label>
      
      <div class="grid">
        <!-- LinkedIn -->
        <div class="col-12 md:col-4 field">
          <span class="p-input-icon-left w-full">
            <i class="fab fa-linkedin"></i>
            <input type="text" pInputText [value]="user?.linkedin || 'No especificado'" 
                   class="w-full" [disabled]="true"/>
          </span>
        </div>

        <!-- Twitter -->
        <div class="col-12 md:col-4 field">
          <span class="p-input-icon-left w-full">
            <i class="fab fa-twitter"></i>
            <input type="text" pInputText [value]="user?.twitter || 'No especificado'" 
                   class="w-full" [disabled]="true"/>
          </span>
        </div>

        <!-- Instagram -->
        <div class="col-12 md:col-4 field">
          <span class="p-input-icon-left w-full">
            <i class="fab fa-instagram"></i>
            <input type="text" pInputText [value]="user?.instagram || 'No especificado'" 
                   class="w-full" [disabled]="true"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</p-card>