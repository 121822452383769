<div class="card">
  <div class="card-container justify-content-start">
    <p-card>
      <ng-template pTemplate="title"> Contenido</ng-template>
      <ng-template pTemplate="subtitle">
        Gestiona el contenido para tus fans
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-row justify-content-end pb-4">
          <button
            class="p-button"
            icon="fas fa-plus"
            label="Añadir contenido"
            pButton
            pRipple
            type="button"
            (click)="showAddContentDialog()"
          ></button>
        </div>

        <div>
          <p-table [value]="content">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 5%;"></th>
                <th>Nombre</th>
                <th style="width: 12%;">Acceso</th>
                <th style="width: 12%;">Tamaño</th>
                <th style="width: 5%;">
                  <i pTooltip="Visualizaciones" tooltipPosition="top" class="pi pi-eye"></i>
                </th>
                <th style="width: 15%;"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-element>
              <tr>
                <td style="width: 10px;">
                  <i pTooltip="Enlace" tooltipPosition="top" *ngIf="element.contentType==='external_url'" class="pi pi-link"></i>
                  <i pTooltip="Fichero ({{element.mediaType}})" tooltipPosition="top" *ngIf="element.contentType==='file'" class="pi pi-file"></i>
                </td>
                <td>{{ element.name }}</td>
                <td>{{ getAccessControl(element.accessControl) }}</td>
                <td>{{ element.size ? roundSize(element.size) : "-" }}</td>
                <td>{{ element.views }}</td>
                <td>
                  <div class="flex">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="fas fa-link"
                      pTooltip="Copiar enlace"
                      tooltipPosition="top"
                      class="p-button-rounded p-button-text"
                      (click)="copyLink(element.id)"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="fas fa-unlock"
                      pTooltip="Cambiar acceso"
                      tooltipPosition="top"
                      class="p-button-rounded p-button-text"
                      (click)="updateContent(element.id)"
                    ></button>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="fas fa-trash"
                      pTooltip="Borrar"
                      tooltipPosition="top"
                      class="p-button-rounded p-button-text"
                      (click)="removeContent(element.id)"
                    ></button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>

<app-add-content-dialog
  [eventId]="eventId"
  [displayAddContentDialog]="displayAddContentDialog"
  (hideDialog)="hideDialog('add', $event)"
></app-add-content-dialog>
<app-update-content-dialog
  [eventId]="eventId"
  [contentId]="selectedContentId"
  [displayUpdateContentDialog]="displayUpdateContentDialog"
  (hideDialog)="hideDialog('update', $event)"
></app-update-content-dialog>
