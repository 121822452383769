<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <div class="flex flex-row align-items-center justify-content-between mb-3" style="flex: 0 1 auto;">
      <div class="flex-grow-1">
        <h4>{{ fullName !== 'Desconocido'? fullName: user?.email}}</h4>
      </div>
      <div class="flex flex-row align-items-center">
        <!-- Actions row -->
      </div>
    </div>

    <div class="flex flex-row">
      <!-- The secondary menu -->
      <div>
        <p-menu [model]="items"></p-menu>
      </div>
      <!-- The content -->
      <div class="content ml-3">
        <!-- Section summary -->
        <div *ngIf="!section || section === 'summary'">
          <p-card>
            <ng-template pTemplate="title">
              <div class="flex align-items-center gap-2">
                <i class="fas fa-chart-line"></i>
                <span>Ciclo de vida</span>
              </div>
            </ng-template>
            <ng-template pTemplate="subtitle">Resumen de fidelización del fan</ng-template>
            <div class="flex align-items-start justify-content-start align-items-center analytics-row">
              <div #chartTotalTokens class="box-25"></div>
              <div #chartTotalPurchases class="box-25"></div>
              <div #chartTotalPurchased class="box-25"></div>
              <div #chartTotalUpselling class="box-25"></div>
            </div>
          </p-card>
        </div>
        <!-- Section data -->
        <div *ngIf="section === 'profile'">
          <p-card>
            <ng-template pTemplate="title">
              <div class="flex align-items-center gap-2">
                <i class="fas fa-user-circle"></i>
                <span>Datos del fan</span>
              </div>
            </ng-template>
            <ng-template pTemplate="subtitle">Datos personales y estado del fan</ng-template>
            
            <div class="grid">
              <!-- Nombre completo -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-user mr-2"></i>
                  Nombre completo
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-user"></i>
                  <input type="text" pInputText [value]="getFullName()" class="w-full" [disabled]="true"/>
                </span>
              </div>

              <!-- Email -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-envelope mr-2"></i>
                  Correo electrónico
                </label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <input type="text" pInputText [value]="user?.email" class="w-full" [disabled]="true"/>
                  <button pButton icon="fas fa-copy" class="p-button-text p-button-secondary" 
                          (click)="copyToClipboard(user?.email)" 
                          pTooltip="Copiar email"></button>
                </div>
              </div>

              <!-- Teléfono -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-phone mr-2"></i>
                  Teléfono
                </label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-phone"></i>
                  </span>
                  <input type="text" pInputText [value]="user?.phone || 'No especificado'" class="w-full" [disabled]="true"/>
                  <button *ngIf="user?.phone" pButton icon="fas fa-copy" class="p-button-text p-button-secondary" 
                          (click)="copyToClipboard(user?.phone)"
                          pTooltip="Copiar teléfono"></button>
                </div>
              </div>

              <!-- Estado -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-flag mr-2"></i>
                  Estado
                </label>
                <div class="flex align-items-center">
                  <span class="purchase-status" [class]="getStateClass(user?.state)">
                    {{getState(user?.state)}}
                  </span>
                </div>
              </div>

              <!-- ID Fiscal -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-id-card mr-2"></i>
                  Identificación fiscal
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-id-card"></i>
                  <input type="text" pInputText [value]="user?.fiscalId || 'No especificado'" class="w-full" [disabled]="true"/>
                </span>
              </div>

              <!-- Fecha de nacimiento -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-birthday-cake mr-2"></i>
                  Fecha de nacimiento
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-calendar-alt"></i>
                  <input type="text" pInputText 
                         [value]="user?.birthDate ? (user?.birthDate | date : 'dd/MM/yyyy') : 'No especificada'" 
                         class="w-full" [disabled]="true"/>
                </span>
              </div>

              <!-- Edad -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-user-clock mr-2"></i>
                  Edad
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-birthday-cake"></i>
                  <input type="text" pInputText 
                         [value]="getAge() !== null ? getAge() + ' años' : 'No especificada'" 
                         class="w-full" [disabled]="true"/>
                </span>
              </div>

              <!-- Género -->
              <div class="col-12 md:col-6 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-venus-mars mr-2"></i>
                  Género
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-user"></i>
                  <input type="text" pInputText [value]="user?.genre || 'No especificado'" class="w-full" [disabled]="true"/>
                </span>
              </div>

              <!-- Separador para Preferencias de Comunicación -->
              <div class="col-12">
                <div class="flex align-items-center gap-2 mt-4 mb-3">
                  <i class="fas fa-bell text-xl"></i>
                  <h5 class="m-0">Preferencias de comunicación</h5>
                </div>
              </div>

              <!-- Email Enabled -->
              <div class="col-12 md:col-4 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-envelope-open mr-2"></i>
                  Comunicación por email
                </label>
                <div class="flex align-items-center">
                  <span class="purchase-status" [class]="user?.emailEnabled ? 'status-success' : 'status-danger'">
                    {{user?.emailEnabled ? 'Activado' : 'Desactivado'}}
                  </span>
                </div>
              </div>

              <!-- Push Enabled -->
              <div class="col-12 md:col-4 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-mobile-alt mr-2"></i>
                  Notificaciones push
                </label>
                <div class="flex align-items-center">
                  <span class="purchase-status" [class]="user?.pushEnabled ? 'status-success' : 'status-danger'">
                    {{user?.pushEnabled ? 'Activado' : 'Desactivado'}}
                  </span>
                </div>
              </div>

              <!-- Last Updated -->
              <div class="col-12 md:col-4 field">
                <label class="block font-medium mb-2">
                  <i class="fas fa-clock mr-2"></i>
                  Última actualización
                </label>
                <span class="p-input-icon-left w-full">
                  <i class="fas fa-calendar-alt"></i>
                  <input type="text" pInputText 
                         [value]="user?.preferencesLastUpdated ? (user?.preferencesLastUpdated | date:'dd/MM/yyyy HH:mm') : 'No especificada'" 
                         class="w-full" [disabled]="true"/>
                </span>
              </div>
            </div>
          </p-card>
        </div>
        <!-- Section public profile -->
        <div *ngIf="section === 'public'">
          <app-public-profile [user]="user"></app-public-profile>
        </div>
        <!-- Section tier -->
        <div *ngIf="section === 'tier'">
          <p-card>
            <ng-template pTemplate="title">
              <div class="flex align-items-center gap-2">
                <i class="fas fa-tags"></i>
                <span>Etiquetas</span>
              </div>
            </ng-template>
            <ng-template pTemplate="subtitle">Añade las etiquetas que quieras al usuario</ng-template>
            <div class="flex flex-column">
              <app-label-input [labels]="user?.labels" (onLabelAdd)="onLabelAdd($event)"
                (onLabelRemove)="onLabelRemove($event)"></app-label-input>
            </div>
          </p-card>
        </div>
        <!-- Section tokens -->
        <div *ngIf="section === 'tokens'">
          <app-token-history [userId]="user?.id"></app-token-history>
        </div>
        <!-- Section purchases -->
        <div *ngIf="section === 'purchases'">
          <app-user-history [purchases]="purchases$"></app-user-history>
        </div>
        <!-- Section interaction -->
        <div *ngIf="section === 'interaction'">
          <app-interaction-history [userId]="user?.id"></app-interaction-history>
        </div>
      </div>
    </div>
  </div>
</div>