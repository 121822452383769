import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Interaction } from '../../model/interaction.interface';
import { UserService } from '../../service/user.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-interaction-history',
  templateUrl: './interaction-history.component.html',
  styleUrls: ['./interaction-history.component.scss']
})
export class InteractionHistoryComponent implements OnInit {
  @Input() userId: string;

  interactions: Interaction[] = [];
  loading: boolean = true;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && changes['userId'].currentValue) {
      this.loadInteractions();
    }
  }

  loadInteractions(): void {
    this.loading = true;
    this.userService.getUserInteractions(this.userId)
      .subscribe(data => {
        this.interactions = data;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  formatDate(date: string): string {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm');
  }

  getChannelLabel(channel: string): string {
    return channel.charAt(0).toUpperCase() + channel.slice(1).toLowerCase();
  }

  getTypeLabel(type: string): string {
    const typeLabels: { [key: string]: string } = {
      'Send': 'Enviado',
      'Delivery': 'Recibido',
      'Open': 'Abierto',
      'Click': 'Click',
      'Complaint': 'Spam',
      'Bounce': 'Rebotado'
    };
    return typeLabels[type] || type;
  }

  getTypeClass(type: string): string {
    const typeClasses: { [key: string]: string } = {
      'Send': 'status-info',
      'Delivery': 'status-info',
      'Open': 'status-success',
      'Click': 'status-success',
      'Complaint': 'status-danger',
      'Bounce': 'status-warning'
    };
    return typeClasses[type] || '';
  }

  clear(table: any): void {
    table.clear();
  }
} 