<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<!-- Onboarding sin navegación -->
<ng-container *ngIf="router.url.includes('/onboarding')">
  <router-outlet></router-outlet>
</ng-container>

<!-- Layout normal para todas las demás páginas -->
<ng-container *ngIf="!router.url.includes('/onboarding')">
  <div class="layout-container" [ngClass]="{'login-layout': hideMenu}">
    <!-- Only show header and menu if not in login page -->
    <ng-container *ngIf="!hideMenu">
      <div class="flex flex-row header-wrapper">
        <p-menubar [model]="items" class="height">
          <ng-template pTemplate="start">
            <div class="flex flex-none align-items-center justify-content-center pl-3 tenant-logo mr-3">
              <img [src]="logoUrl" height="24px" [routerLink]="['/home']" />
            </div>
          </ng-template>
          <ng-template pTemplate="end">
            <div *ngIf="isSuperAdmin" class="flex align-items-center mr-4">
              <p-dropdown [options]="tenants" [(ngModel)]="selectedTenantId" optionLabel="name" optionValue="id"
                placeholder="Selecciona un tenant" (onChange)="onTenantChange($event.value)"
                [disabled]="!tenants.length" styleClass="tenant-dropdown" [filter]="true" [filterBy]="'name'"
                filterPlaceholder="Buscar tenant..." [showClear]="true">

                <ng-template pTemplate="item" let-tenant>
                  <div class="flex align-items-center">
                    <span>{{ tenant.name }}</span>
                    <p-tag *ngIf="tenant.id === currentTenantId" value="Actual" severity="info" class="ml-2">
                    </p-tag>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="flex align-content-center flex-wrap card-container mr-6">
              <div class="initials" (click)="op.toggle($event)">
                {{getInitials(name)}}
              </div>
              <p-overlayPanel #op>
                <ng-template pTemplate>
                  <div class="flex flex-column">
                    <div class="flex align-items-center justify-content-start">
                      <h3>{{name}}</h3>
                    </div>
                    <div class=" mb-3">
                      <span class="mb-2 font-semibold">Tu plan</span>
                      <div>
                        <p-tag class="ml-2 uppercase" [value]="tier?.name"></p-tag>
                      </div>
                    </div>
                    <div>
                      <span class="mb-2 font-semibold">Almacenamiento</span>
                      <p-progressBar [value]="storageValue"></p-progressBar>
                      <p>0.00 GB utilizados de {{ tier?.storageGb }}GB</p>
                    </div>
                    <div>
                      <span class="mb-2 font-semibold">Comunicaciones</span>
                      <p-progressBar [value]="communicationsValue"></p-progressBar>
                      <p>0 enviadas de {{ tier?.communicationsPerMonth }}</p>
                    </div>
                    <div>
                      <span class="mb-2 font-semibold">Segmentos</span>
                      <p-progressBar [value]="segmentsValue"></p-progressBar>
                      <p>0 creados de {{ tier?.segments }}</p>
                    </div>

                    <p-divider></p-divider>
                    <div class="flex align-items-center justify-content-start">
                      <button pButton icon="pi pi-globe" type="button" label="Mi web" class="p-button-text w-full"
                        [routerLink]="['/web']"></button>
                    </div>
                    <div class="flex align-items-center justify-content-start">
                      <button pButton icon="pi pi-user" type="button" label="Mi usuario" class="p-button-text w-full"
                        [routerLink]="['/user-settings']"></button>
                    </div>
                    <div class="flex align-items-center justify-content-start">
                      <button pButton icon="pi pi-building" type="button" label="Mi organización"
                        class="p-button-text w-full" [routerLink]="['/settings']"></button>
                    </div>
                    <div class="flex align-items-center justify-content-start">
                      <button pButton icon="pi pi-angle-double-up" type="button" label="Mejora tu plan"
                        class="p-button-text w-full" (click)="openImprovePlan()"></button>
                    </div>
                    <div class="flex align-items-center justify-content-start">
                      <button pButton icon="pi pi-sign-out" type="button" label="Cerrar sesión"
                        class="p-button-text w-full" (click)="logout()"></button>
                    </div>
                  </div>

                </ng-template>
              </p-overlayPanel>
            </div>
          </ng-template>
        </p-menubar>
      </div>
      <div class="flex flex-row flex-grow-1 container">
        <div id="menu" class="flex-initial flex px-5 py-3">
          <!--<app-menu></app-menu>-->
        </div>
        <div id="content" class="flex-grow-1 flex">
          <div style="width: 100%" class="flex flex-column">
            <div id="outlet-container" class="p-5">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Show only router-outlet if in login page -->
    <ng-container *ngIf="hideMenu">
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</ng-container>