import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
  HttpContextToken
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError, of, timeout } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TokenResponse } from 'src/app/auth/model/token-response.interface';
import { jwtDecode } from 'jwt-decode';

export const SKIP_AUTH_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly TOKEN_COOKIE_KEY = 'access_token';
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private retryCount = 0;
  private readonly MAX_RETRIES = 3;
  private readonly TOKEN_REFRESH_GRACE_PERIOD = 120; // seconds
  
  constructor(
    private readonly cookieService: CookieService,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<unknown>, 
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Skip authentication for specific requests
    if (request.context.get(SKIP_AUTH_INTERCEPTOR)) {
      return next.handle(request);
    }

    return this.handleRequest(request, next);
  }

  private handleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.cookieService.get(this.TOKEN_COOKIE_KEY);

    // If no token exists, proceed without authorization
    if (!accessToken) {
      return next.handle(request).pipe(
        catchError((error) => this.handleError(error, request, next))
      );
    }

    // Force refresh if we're close to token expiry (proactive refresh)
    if (!this.isTokenValid(accessToken) || this.isTokenNearExpiry(accessToken)) {
      console.debug('Token expired or nearing expiry, refreshing token...');
      return this.handleTokenRefresh(request, next);
    }

    // Token is valid, proceed with the request
    const authorizedRequest = this.addAuthorizationHeader(request, accessToken);
    return next.handle(authorizedRequest).pipe(
      catchError((error: unknown) => this.handleError(error, request, next))
    );
  }

  private handleTokenRefresh(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.retryCount++;
      console.debug(`Attempting token refresh (attempt ${this.retryCount})`);

      return this.authService.refreshToken().pipe(
        switchMap((token: TokenResponse) => {
          console.debug('Token refresh successful in interceptor');
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addAuthorizationHeader(request, token.access_token));
        }),
        catchError((error) => {
          console.error('Token refresh failed in interceptor:', error);
          this.resetRefreshState();
          
          // Only logout on authentication errors after multiple retries
          if ((error?.status === 401 || error?.status === 403) && this.retryCount >= this.MAX_RETRIES) {
            console.error('Max refresh retries reached, logging out');
            this.authService.performLogout();
          }
          
          return throwError(() => error);
        })
      );
    }

    console.debug('Refresh already in progress, waiting...');
    // Wait for the ongoing refresh to complete with a timeout
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      timeout(10000), // Add 10 second timeout to avoid hanging
      switchMap(token => {
        console.debug('Using newly refreshed token');
        return next.handle(this.addAuthorizationHeader(request, token.access_token));
      }),
      catchError(error => {
        console.error('Error while waiting for token refresh:', error);
        // If waiting for token refresh fails, try the request anyway
        // This allows navigation to continue even during refresh issues
        return next.handle(request).pipe(
          catchError(innerError => {
            if (innerError?.status === 401 || innerError?.status === 403) {
              // Let 401/403 errors pass through normally to avoid infinite loops
              return throwError(() => innerError);
            }
            // For other errors, retry the request
            return next.handle(request);
          })
        );
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private isTokenValid(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const expectedIssuer = 'https://nevent.es';
      
      return (
        decoded.iss === expectedIssuer && 
        (decoded.exp * 1000) > (Date.now() + (this.TOKEN_REFRESH_GRACE_PERIOD * 1000))
      );
    } catch {
      return false;
    }
  }

  private isTokenNearExpiry(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const expiryTime = decoded.exp * 1000;
      const currentTime = Date.now();
      const timeToExpiry = expiryTime - currentTime;
      
      // Consider token near expiry if less than 5 minutes left
      return timeToExpiry < 300000;
    } catch {
      return true; // If we can't decode, assume it's near expiry
    }
  }

  private handleError(error: unknown, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case HttpStatusCode.Unauthorized:
          console.debug('401 Unauthorized - attempting token refresh');
          if (this.retryCount >= this.MAX_RETRIES) {
            console.error('Max retry attempts reached, logging out');
            this.resetRefreshState();
            this.authService.performLogout();
            return throwError(() => new Error('Max retry attempts reached'));
          }
          return this.handleTokenRefresh(request, next);
          
        case HttpStatusCode.Forbidden:
          console.debug('403 Forbidden - attempting token refresh');
          // Only logout if we've already tried refreshing the token multiple times
          if (this.retryCount >= this.MAX_RETRIES) {
            console.error('Max retry attempts reached after 403, logging out');
            this.authService.performLogout();
            return throwError(() => error);
          }
          // Otherwise try refreshing the token
          return this.handleTokenRefresh(request, next);
          
        default:
          return throwError(() => error);
      }
    }
    return throwError(() => error);
  }

  private resetRefreshState(): void {
    this.isRefreshing = false;
    this.refreshTokenSubject.next(null);
    this.retryCount = 0;
  }
}