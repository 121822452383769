import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OnboardingService } from '../services/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {
  
  constructor(
    private onboardingService: OnboardingService,
    private router: Router
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // Verificar si el onboarding está marcado como completo en localStorage
    const onboardingCompleted = localStorage.getItem('onboardingCompleted') === 'true';
    
    // Si está marcado como completo en localStorage, permitir acceso directo
    if (onboardingCompleted) {
      return of(true);
    }
    
    // Verificar si el usuario ya ha completado el onboarding
    return this.onboardingService.getOnboardingSession().pipe(
      map(session => {
        // Simular que el onboarding está completo para desarrollo
        session.completed = true;
        
        // Guardar en localStorage para futuras verificaciones
        localStorage.setItem('onboardingCompleted', 'true');
        
        if (session.completed) {
          // Si el onboarding está completo, permite acceder a la ruta solicitada
          return true;
        } else {
          // Si no está completo y no estamos ya en el onboarding, redirige
          if (!state.url.includes('/onboarding')) {
            this.router.navigate(['/onboarding']);
          }
          return false;
        }
      }),
      catchError(error => {
        console.error('Error verificando el estado del onboarding:', error);
        // En caso de error, permitimos acceder para evitar bloquear al usuario
        // Además, marcamos el onboarding como completo para evitar futuros problemas
        localStorage.setItem('onboardingCompleted', 'true');
        return of(true);
      })
    );
  }
} 