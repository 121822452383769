<div class="card">
    <p-table 
        #purchasesTable
        [value]="purchaseList" 
        [paginator]="purchaseList?.length > 0"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]"
        [loading]="loading"
        styleClass="p-datatable-striped"
        [tableStyle]="{'min-width': '50rem'}"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} compras"
        [globalFilterFields]="['purchasedAt','state','providerId','totalPrice','eventName']">
        
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between align-items-center">
                <h3 class="m-0">Compras ({{ purchaseList?.length || 0 }})</h3>
                <div class="flex align-items-center gap-2">
                    <button
                        pButton
                        label="Borrar filtros"
                        class="p-button-outlined"
                        icon="pi pi-filter-slash"
                        (click)="purchasesTable.clear()">
                    </button>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="purchasesTable.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar compra...">
                    </span>
                </div>
            </div>
        </ng-template>
        
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="purchasedAt">Fecha <p-sortIcon field="purchasedAt"></p-sortIcon></th>
                <th pSortableColumn="state">Estado <p-sortIcon field="state"></p-sortIcon></th>
                <th pSortableColumn="providerId">Canal <p-sortIcon field="providerId"></p-sortIcon></th>
                <th pSortableColumn="totalPrice" style="text-align: right">Importe <p-sortIcon field="totalPrice"></p-sortIcon></th>
                <th pSortableColumn="eventName">Experiencia <p-sortIcon field="eventName"></p-sortIcon></th>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="body" let-purchase>
            <tr (click)="onRowClick(purchase)" class="cursor-pointer">
                <td>{{formatDate(purchase.purchasedAt)}}</td>
                <td>
                    <span class="purchase-status" [class]="getStateClass(purchase.state)">
                        {{getStateLabel(purchase.state)}}
                    </span>
                </td>
                <td>{{getChannel(purchase)}}</td>
                <td style="text-align: right">{{formatAmount(purchase.totalPrice)}}</td>
                <td>{{getExperienceName(purchase)}}</td>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">
                    <div class="empty-message">
                        <i class="pi pi-shopping-cart"></i>
                        <h3>No hay compras realizadas</h3>
                        <p>Cuando realices una compra, aparecerá en este listado</p>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingbody">
            <tr *ngFor="let _ of [1,2,3,4,5]">
                <td><p-skeleton width="120px"></p-skeleton></td>
                <td>
                    <p-skeleton width="100px" height="24px" styleClass="purchase-status"></p-skeleton>
                </td>
                <td><p-skeleton width="150px"></p-skeleton></td>
                <td style="text-align: right"><p-skeleton width="80px"></p-skeleton></td>
                <td><p-skeleton width="200px"></p-skeleton></td>
            </tr>
        </ng-template>
    </p-table>
</div>
