<p-toast position="top-right"></p-toast>
<div class="container">
  <div class="sidebar" [class.collapsed]="isSidebarCollapsed">
    <button pButton type="button" [icon]="isSidebarCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-left'"
      class="toggle-btn p-button-text" (click)="toggleMenu()"></button>
    <p-menu [model]="items" class="menu"
      [styleClass]="isSidebarCollapsed ? 'menu-collapsed' : 'menu-expanded'"></p-menu>
  </div>
  <div class="main-content">
    <div class="header">
      <div class="header-content">
        <div class="event-title">
          <h4>{{ event?.description?.name }}</h4>
        </div>
        <div class="action-buttons">
          <div class="mr-3">
            <p-tag [value]="getState(event?.state)"
              [ngClass]="event?.state==='ACTIVE' ? 'active' : 'not-active'"></p-tag>
          </div>
          <div *ngIf="event?.state === 'DRAFT'">
            <button *ngIf="!publishing; else publishBlocked" class="p-button-success mr-3" label="Publicar" pButton
              pRipple type="button" (click)="publish()"></button>
            <ng-template #publishBlocked>
              <button class="p-button-success mr-3" label="Publicar" icon="fas fa-spinner fa-spin" iconPos="right"
                pButton pRipple type="button" [disabled]="true"></button>
            </ng-template>
          </div>
          <div>
            <button *ngIf="event?.state === 'ACTIVE'; else publishButton" class="p-button-text mr-3" pButton pRipple
              icon="fas fa-stop" pTooltip="Pausar experiencia" tooltipPosition="bottom" type="button"
              (click)="activate(true)"></button>
            <ng-template #publishButton>
              <button class="p-button-text mr-3" pButton pRipple icon="fas fa-play" pTooltip="Activar experiencia"
                tooltipPosition="bottom" type="button" (click)="activate(false)"></button>
            </ng-template>
          </div>
          <div>
            <button *ngIf="event?.hidden;else hideButton" class="p-button-text mr-3" pButton pRipple icon="fas fa-eye"
              pTooltip="Listar en el catálogo" tooltipPosition="bottom" type="button"
              (click)="setHidden(false)"></button>
            <ng-template #hideButton>
              <button class="p-button-text mr-3" pButton pRipple icon="fas fa-eye-slash" pTooltip="Ocultar del catálogo"
                tooltipPosition="bottom" type="button" (click)="setHidden(true)"></button>
            </ng-template>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-table-cells-large" pTooltip="Catálogo de servicios"
              tooltipPosition="bottom" type="button" (click)="showServices()"></button>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-link" pTooltip="Copiar url"
              tooltipPosition="bottom" type="button" (click)="copyLink()"></button>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-qrcode" pTooltip="Ver QR"
              tooltipPosition="bottom" type="button" (click)="viewQR()"></button>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-volume-high" pTooltip="Notificar a fans"
              tooltipPosition="bottom" type="button" (click)="notifyFans()"
              [disabled]="event?.tenantMembersNotification?.sent ?? false"></button>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-clone" pTooltip="Clonar"
              tooltipPosition="bottom" type="button" (click)="clone()"></button>
          </div>
          <div>
            <button class="p-button-text mr-3" pButton pRipple icon="fas fa-trash" pTooltip="Eliminar"
              tooltipPosition="bottom" type="button" (click)="confirm()"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <div class="content-inner">
          <div *ngIf="!section || section === 'summary'">
            <app-event-detail-summary [eventId]="event?.id"></app-event-detail-summary>
          </div>
          <div *ngIf="section === 'description'">
            <app-event-detail-description [eventId]="event?.id"
              [description]="event?.description"
              [providerEventId]="event?.providerEventId"></app-event-detail-description>
          </div>
          <div *ngIf="section === 'venue'">
            <app-event-detail-venue [eventId]="event?.id" [venue]="event?.venue" [providerEventId]="event?.providerEventId"></app-event-detail-venue>
          </div>
          <div *ngIf="section === 'tickets'">
            <app-event-detail-tickets [eventId]="event?.id" [tickets]="event?.tickets" [startDate]="event?.venue?.startDate" [endDate]="event?.venue?.endDate" [providerEventId]="event?.providerEventId"></app-event-detail-tickets>
          </div>
          <div *ngIf="section === 'experiences'">
            <app-event-detail-experiences [eventId]="event?.id"
              [experiences]="event?.experiences" [providerEventId]="event?.providerEventId"></app-event-detail-experiences>
          </div>
          <div *ngIf="section === 'sponsors'">
            <app-event-detail-sponsors [eventId]="event?.id" [sponsors]="event?.sponsors"></app-event-detail-sponsors>
          </div>
          <div *ngIf="section === 'sales'">
            <app-event-detail-sales [eventId]="event?.id"></app-event-detail-sales>
          </div>
          <div *ngIf="section === 'attendees'">
            <app-event-detail-attendees [eventId]="event?.id"></app-event-detail-attendees>
          </div>
          <div *ngIf="section === 'messages'">
            <app-event-detail-messages [eventId]="event?.id"></app-event-detail-messages>
          </div>
          <div *ngIf="section === 'content'">
            <app-event-detail-content [eventId]="event?.id" [content]="event?.content"></app-event-detail-content>
          </div>
          <div *ngIf="section === 'public-relations'">
            <app-event-detail-public-relations [eventId]="event?.id" [eventUrl]="event?.url"
              [content]="event?.content"></app-event-detail-public-relations>
          </div>
          <div *ngIf="section === 'streaming'">
            <app-event-detail-streaming [eventId]="event?.id"
              [streaming]="event?.streaming"></app-event-detail-streaming>
          </div>
          <div *ngIf="section === 'moments'">
            <app-event-detail-moments [eventId]="event?.id"></app-event-detail-moments>
          </div>
          <div *ngIf="section === 'coupons'">
            <app-event-detail-coupons [eventId]="event?.id"></app-event-detail-coupons>
          </div>
          <div *ngIf="section === 'reassignation'">
            <app-event-detail-reassignation [eventId]="event?.id"></app-event-detail-reassignation>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="fas fa-triangle-exclamation"></p-confirmDialog>
<p-dialog header="Descargar QR" [modal]="true" [(visible)]="showQRDiaglog">
  <div class="flex flex-column justify-content-center align-items-center">
    <p>Descarga este código QR y utilizalo para atraer tráfico a tu evento.</p>
    <app-event-qr [eventId]="event?.id" [showImage]="true" [filename]="event?.description?.name"></app-event-qr>
  </div>
</p-dialog>

<p-sidebar [(visible)]="showServicesDialog" position="right" [baseZIndex]="10000" styleClass="custom-sidebar"
  (onHide)="refreshPage()">
  <ng-template pTemplate="header">
    <h3>Catálogo de servicios</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <p>Todo lo que necesitas para convertir tu evento en una experiencia única</p>
    <div class="flex flex-column">
      <div *ngFor="let item of catalog" class="flex align-items-center justify-content-start mb-3">
        <app-event-detail-catalog-item [item]="item" [eventId]="eventId"
          [services]="event?.services"></app-event-detail-catalog-item>
      </div>
    </div>
  </ng-template>
</p-sidebar>

<script>
  function toggleMenu() {
    document.getElementById('sidebar').classList.toggle('collapsed');
  }
</script>
