import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OnboardingQuestion, OnboardingResponse, OnboardingSession } from '../models/onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private apiUrl = `${environment.apiUrl}/signup`;

  constructor(private http: HttpClient) {}

  /**
   * Obtiene la sesión actual de onboarding
   */
  getOnboardingSession(): Observable<OnboardingSession> {
    return this.http.get<OnboardingSession>(`${this.apiUrl}/status`);
  }

  /**
   * Guarda una respuesta y avanza al siguiente paso
   */
  saveResponse(response: OnboardingResponse): Observable<OnboardingSession> {
    return this.http.post<OnboardingSession>(`${this.apiUrl}/steps/${response.questionId}`, response);
  }

  /**
   * Retrocede al paso anterior
   */
  previousStep(): Observable<OnboardingSession> {
    return this.http.put<OnboardingSession>(`${this.apiUrl}/steps/previous`, {});
  }

  /**
   * Completa el onboarding
   */
  completeOnboarding(): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/complete`, {});
  }

  /**
   * Establece el paso actual del onboarding
   */
  setCurrentStep(step: number): Observable<OnboardingSession> {
    return this.http.put<OnboardingSession>(`${this.apiUrl}/steps/current`, { step });
  }

  /**
   * Guarda parcialmente la información del paso actual
   * @param stepId ID del paso actual
   * @param stepData Datos del paso a guardar
   */
  savePartialData(stepId: number, stepData: any): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/steps/${stepId}`, stepData);
  }
} 