import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserComponent } from './user.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { PurchaseDetailComponent } from './components/purchase-detail/purchase-detail.component';
import { TokenDetailComponent } from './components/token-detail/token-detail.component';
import { InteractionHistoryComponent } from './components/interaction-history/interaction-history.component';

const routes: Routes = [
  {
    path: '', component: UserComponent,
  },
  {
    path: ':id', component: UserDetailComponent,
    children: [
      { path: '', redirectTo: 'summary', pathMatch: 'full' },
      { path: 'summary', component: UserDetailComponent },
      { path: 'profile', component: UserDetailComponent },
      { path: 'public', component: UserDetailComponent },
      { path: 'tier', component: UserDetailComponent },
      { path: 'interaction', component: InteractionHistoryComponent },
      {
        path: 'tokens',
        component: UserDetailComponent,
        children: [
          {
            path: ':tokenId',
            component: TokenDetailComponent,
            children: [
              { path: '', redirectTo: 'summary', pathMatch: 'full' },
              { path: 'summary', component: TokenDetailComponent },
              { path: 'assignation', component: TokenDetailComponent },
              { path: 'experiences', component: TokenDetailComponent },
              { path: 'products', component: TokenDetailComponent },
              { path: 'wallet', component: TokenDetailComponent },
              { path: 'messages', component: TokenDetailComponent }
            ]
          }
        ]
      },
      {
        path: 'purchases',
        component: UserDetailComponent,
        children: [
          { path: ':id', component: PurchaseDetailComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }