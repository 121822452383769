<div class="stepsdemo-content table-container">
  <p-card>
    <ng-template pTemplate="title">
      Celebración
      <span *ngIf="isExternalEvent" class="text-sm text-gray-500 ml-2">(Evento externo - Solo lectura)</span>
    </ng-template>
    <ng-template pTemplate="subtitle">
      Introduce los detalles de asistencia a la experiencia
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex">
        <div>
          <div class="p-fluid">
            <div class="field">
              <label for="startDate">Fecha de comienzo *</label>
              <div>
                <p-calendar 
                  appendTo="body" 
                  [showButtonBar]="true" 
                  id="startDate" 
                  [minDate]="minStartDate"
                  [(ngModel)]="startDate" 
                  [showTime]="true" 
                  placeholder="dd/MM/yyyy HH:mm"
                  (onSelect)="updateReactiveForm()" 
                  icon="fas fa-calendar"
                  [disabled]="isExternalEvent">
                </p-calendar>
              </div>
              <small *ngIf="
                  this.venueForm.get('startDate')?.invalid &&
                  (this.venueForm.get('startDate')?.dirty ||
                    this.venueForm.get('startDate')?.touched)
                " id="name-help" class="p-error block">La fecha y hora de comienzo es obligatoria.</small>
            </div>

            <div class="field">
              <label for="endDate">Fecha de fin *</label>
              <div>
                <p-calendar 
                  appendTo="body" 
                  [showButtonBar]="true" 
                  id="endDate" 
                  [minDate]="startDate"
                  [(ngModel)]="endDate" 
                  [showTime]="true" 
                  placeholder="dd/MM/yyyy HH:mm" 
                  icon="fas fa-calendar"
                  (onSelect)="updateReactiveForm()"
                  [disabled]="isExternalEvent">
                </p-calendar>
              </div>
            </div>

            <div class="field">
              <label for="openingTime">Apertura de puertas</label>
              <div>
                <p-calendar 
                  appendTo="body" 
                  [showTime]="true" 
                  [timeOnly]="true" 
                  [maxDate]="startDate"
                  [maxTime]="startDate" 
                  [(ngModel)]="openingTime" 
                  placeholder="HH:mm"
                  (onSelect)="updateReactiveForm()"
                  [disabled]="isExternalEvent">
                </p-calendar>
              </div>
            </div>

            <div class="field">
              <label for="search">Lugar de celebración *</label>
              <input 
                type="text" 
                class="w-full" 
                placeholder="Buscar localización" 
                autocorrect="off" 
                autocapitalize="off"
                spellcheck="off" 
                type="text" 
                pInputText 
                [(ngModel)]="searchFieldModel" 
                #search
                [disabled]="isExternalEvent" />
              <small *ngIf="
                  this.venueForm.get('location')?.invalid &&
                  (this.venueForm.get('location')?.dirty ||
                    this.venueForm.get('location')?.touched)
                " id="name-help" class="p-error block">El lugar de celebración es obligatorio.</small>
            </div>

            <p-panel 
              header="Opciones avanzadas" 
              [toggleable]="true" 
              [collapsed]="true" 
              expandIcon="fas fa-angle-down"
              collapseIcon="fas fa-angle-up">
              <div class="field">
                <p-checkbox 
                  [(ngModel)]="hiddenLocation" 
                  [binary]="true" 
                  label="Ocultar ubicación al público"
                  inputId="hiddenLocation" 
                  (onChange)="updateReactiveForm()"
                  [disabled]="isExternalEvent">
                </p-checkbox>
                <i class="pi pi-question-circle ml-3"
                  pTooltip="Oculta la localización y revélala cuando quieras de forma automática">
                </i>
              </div>

              <div class="field" *ngIf="hiddenLocation === true">
                <label for="revealLocationAt">¿Cuándo quieres revelar la ubicación?</label>
                <div>
                  <p-calendar 
                    appendTo="body" 
                    [showButtonBar]="true" 
                    id="revealLocationAt"
                    [(ngModel)]="revealLocationAt" 
                    [showTime]="true" 
                    placeholder="dd/MM/yyyy HH:mm"
                    icon="fas fa-calendar" 
                    (onSelect)="updateReactiveForm()" 
                    [maxDate]="startDate"
                    [disabled]="isExternalEvent">
                  </p-calendar>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
        <div class="ml-4">
          <div id="map"></div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between">
        <div class="flex-none">
          <button 
            pButton 
            class="p-button-text" 
            *ngIf="!eventId && !isExternalEvent" 
            label="Atrás" 
            (click)="prevPage()"
            icon="fas fa-angle-left">
          </button>
        </div>
        <div class="flex-grow-1"></div>
        <div>
          <button 
            pButton 
            class="p-button-text" 
            *ngIf="!eventId && !isExternalEvent" 
            label="Guardar borrador"
            (click)="saveDraft()">
          </button>
          <button 
            pButton 
            class="p-button-text" 
            *ngIf="!eventId && !isExternalEvent" 
            [disabled]="!venueForm.valid" 
            label="Siguiente"
            (click)="nextPage()" 
            icon="fas fa-angle-right" 
            iconPos="right">
          </button>
          <button 
            pButton 
            class="p-button" 
            *ngIf="eventId && !isExternalEvent" 
            [disabled]="!venueForm.valid || !venueForm.dirty"
            label="Guardar cambios" 
            (click)="updateEvent()">
          </button>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>