import { CampaignRequest } from './../model/campain-request.interface';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagedResponse } from 'src/app/shared/model/paged-response.interface';
import { Coupon } from '../model/coupon.interface';
import { EventExperience, EventResponse, EventSponsor, EventTickets } from '../model/event-detail.interface';
import { Merch } from '../model/merch';
import { ItemType } from 'src/app/shared/enums/item-type.enum';
import { AdditionalAssignationItem } from '../assistant/helper/form.heper';
import { EventInfo } from '../assistant/components/step2-inputs/components/step2-inputs-events/step2-inputs-events.component';

export interface ActionTypeItem {
  id: string;
  name: string;
  description: string;
  icon: string;
  disabled: boolean;
  requiredItemType: 'EVENT' | 'COMPLEMENT' | 'SUPPLEMENT' | 'COUPON' | 'MESSAGE' | 'TICKET_TYPE';
  inputs: AdditionalAssignationItem[];
}

export interface ActionTypeCategory {
  category: string;
  icon: string;
  items: ActionTypeItem[];
  hasEnabledItems?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignService {


  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  createCampaign(campaingData: any): Observable<any> {

    const url = `${this.baseUrl}/campaigns/create`

    return this.http.post<any>(url, campaingData);
  }

  pauseCampaign(campaignId: string): Observable<any> {
    return this.http.post<void>(`${this.baseUrl}/campaigns/${campaignId}/actions/pause`, {});
  }

  resumeCampaign(campaignId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/campaigns/${campaignId}/actions/enable`, {});
  }

  findAll(endpoint: string): Observable<any[]> {
    const url = `${this.baseUrl}/${endpoint}`;
    return this.http.get<any[]>(url);
  }

  getSegments(): Observable<any[]> {
    const url = `${this.baseUrl}/lists`;
    return this.http.get<any[]>(url);
  }

  getComplements(): Observable<any[]> {
    const url = `${this.baseUrl}/complements`;
    return this.http.get<any[]>(url);
  }

  getTickets(eventId: any) {

    let url = `${this.baseUrl}/events/${eventId}/tickets/types`;

    return this.http.get<EventTickets[]>(url);
  }

  getExperiences(eventId: string) {

    let url = `${this.baseUrl}/events/${eventId}/experiences`;

    return this.http.get<EventExperience[]>(url);
  }

  getCouponsByEventId(eventId: string): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(`${this.baseUrl}/events/${eventId}/coupons`);
  }

  getMerch(): Observable<Merch[]> {
    return this.http.get<Merch[]>(`${this.baseUrl}/merch`);
  }

  sendMessage(itemType: ItemType, itemId: string, message: string, eventId: string, redirectionService: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/campaigns/message`, {
      itemType,
      itemId,
      message,
      eventId,
      redirectionService
    });
  }

  /**
   * Fetches all action types grouped by category
   * @returns Observable of ActionTypeCategory array
   */
  getActionTypesByCategory(): Observable<ActionTypeCategory[]> {
    return this.http.get<ActionTypeCategory[]>(`${this.baseUrl}/action-types/by-category`).pipe(
      map(categories => {
        // First, process each category to:
        // 1. Sort items within category (enabled first, then alphabetically)
        // 2. Add hasEnabledItems flag
        const processedCategories = categories.map(category => ({
          ...category,
          items: this.sortActionTypeItems(category.items),
          hasEnabledItems: category.items.some(item => !item.disabled)
        }));

        // Then sort categories (ones with enabled items first, then alphabetically)
        return this.sortCategories(processedCategories);
      })
    );
  }

  generateCopys(goal: string, inputs: any, segment: any, context: any, images: any[]): Observable<any> {

    const url = `${this.baseUrl}/test/copy?goal=${goal}`;

    console.log(inputs);

    // Transform inputs object to array of {name, value} objects
    const params = Object.entries(inputs).map(([name, value]) => ({
      name,
      value: value?.toString() || ''
    }));

    const body = {
      params,
      context,
      images,
      segment
    };

    return this.http.post<any>(url, body);
  }

  generateTemplate(goal: string, inputs: any, segment: any, context: any, images: any[], copy: any): Observable<any> {
    const url = `${this.baseUrl}/test/template?goal=${goal}`;

    const params = Object.entries(inputs).map(([name, value]) => ({
      name,
      value: value?.toString() || ''
    }));

    const body = {
      params,
      context,
      images,
      segment,
      copy
    };

    console.log(body);

    return this.http.post<any>(url, body);
  }

  private sortActionTypeItems(items: ActionTypeItem[]): ActionTypeItem[] {
    return items.sort((a, b) => {
      // First, sort by disabled status
      if (a.disabled !== b.disabled) {
        return a.disabled ? 1 : -1;
      }
      // Then alphabetically by name
      return a.name.localeCompare(b.name);
    });
  }

  private sortCategories(categories: ActionTypeCategory[]): ActionTypeCategory[] {
    return categories.sort((a, b) => {
      // First, sort by whether the category has any enabled items
      if (a.hasEnabledItems !== b.hasEnabledItems) {
        return a.hasEnabledItems ? -1 : 1;
      }
      // Then alphabetically by category name
      return a.category.localeCompare(b.category);
    });
  }

  /**
   * Fetches event information from an external URL
   * @param url The external URL to fetch event information from
   * @param forceRefresh Whether to force refresh the data
   * @returns Observable of EventInfo
   */
  getEventInfo(url: string, forceRefresh: boolean = false): Observable<EventInfo> {
    const endpoint = `${this.baseUrl}/test/event`;
    
    const params = new HttpParams()
      .set('url', url)
      .set('forceRefresh', forceRefresh.toString());
    
    return this.http.get<EventInfo>(endpoint, { params });
  }

}
