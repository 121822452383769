import { HttpClient, HttpContext, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError, finalize } from 'rxjs';
import { SignedURL } from 'src/app/event/model/signed-url.interface';
import { environment } from 'src/environments/environment';
import { DomainExists } from '../model/domain-exists.interface';
import { Organization } from '../model/organization.interface';
import {CookieService} from "ngx-cookie-service";
import { checkOnboardingStatus, loginSuccess, logout } from '../store/actions';
import { Store } from '@ngrx/store';
import { TokenResponse } from '../model/token-response.interface';
import { OnboardingStatus } from '../model/onboarding-status.interface';
import { SKIP_AUTH_INTERCEPTOR } from 'src/app/core/interceptor/auth.interceptor';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';

interface TenantRegistrationResponse {
  tenant: any;
  accessToken: string;
  refreshToken: string;
  role: string;
  name: string;
  surname: string;
  displayName: string;
  phone: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'refresh_token';

  // Token expiration times in seconds
  private readonly ACCESS_TOKEN_EXPIRY = 600; // 10 minutes
  private readonly REFRESH_TOKEN_EXPIRY = 604800; // 7 days

  private readonly TOKEN_REFRESH_GRACE_PERIOD = 30; // seconds  

  baseUrl: string = environment.apiUrl;

  // Tracking if a token refresh is in progress
  private isRefreshing = false;

  constructor(private http: HttpClient, private cookieService: CookieService, private store: Store, private router: Router) {
    this.setupTokenRefreshTimer();
  }

  private setupTokenRefreshTimer(): void {
    // Check token validity every 15 seconds
    setInterval(() => {
      const accessToken = this.cookieService.get(this.ACCESS_TOKEN);
      // Don't try to refresh if there's no access token or already refreshing
      if (!accessToken || this.isRefreshing) return;
      
      try {
        // Check if token is close to expiring
        const decoded: any = jwtDecode(accessToken);
        const expiryTime = decoded.exp * 1000;
        const currentTime = Date.now();
        const timeToExpiry = expiryTime - currentTime;
        
        // If token is within 5 minutes of expiring, refresh it
        // Much more aggressive refresh to avoid edge cases
        if (timeToExpiry < 300000 && timeToExpiry > 0) {
          console.debug(`Token expires in ${Math.round(timeToExpiry/1000)}s, refreshing...`);
          this.isRefreshing = true;
          this.refreshToken().pipe(
            catchError(error => {
              console.error('Auto refresh token failed:', error);
              // Only logout on auth errors, not on network issues
              if (error.status === 401 || error.status === 403) {
                this.performLogout();
              }
              return of(null);
            }),
            // Always reset refreshing flag
            finalize(() => {
              this.isRefreshing = false;
            })
          ).subscribe();
        }
      } catch (error) {
        console.error('Error checking token expiry:', error);
        this.isRefreshing = false;
      }
    }, 15000); // Check every 15 seconds
  }  

  getOrganization(): Observable<Organization> {

    const url = `${this.baseUrl}/tenants`

    return this.http.get<Organization>(url);
  }

  checkDomain(domain: string): Observable<any> {
    const url = `${this.baseUrl}/tenants/${domain}/exists`

    return this.http.get<DomainExists>(url);
  }

  getSignedUrl(imageId: string, mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/tenants/${imageId}/upload`

    return this.http.get<SignedURL>(url, { params: { mimeType } });
  }

  complete(data: any): Observable<TenantRegistrationResponse> {
    const url = `${this.baseUrl}/tenants`
    return this.http.post<TenantRegistrationResponse>(url, data);
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
        context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true) // Skip auth interceptor
      });
    return this.http.request(req);
  }

  isTenantConfigured(): Observable<DomainExists> {

    const url = `${this.baseUrl}/tenants/configured`

    return this.http.get<DomainExists>(url);
  }

  getAllTenants(): Observable<Organization[]> {
    const url = `${this.baseUrl}/tenants/all`;
    return this.http.get<Organization[]>(url);
  }

  changeTenant(tenantId: string): Observable<TokenResponse> {
    const url = `${this.baseUrl}/users/tenant`;
    return this.http.post<TokenResponse>(url, { tenantId });
  }

  /**
   * Handles the complete login process:
   * 1. Sets the access token
   * 2. Fetches organization data
   * 3. Updates the store
   */
  completeLogin(accessToken: string, refreshToken: string): Observable<Organization> {
    // First set the token
    this.setTokens(accessToken, refreshToken);
  
    // Then get organization and update store, and check onboarding status
    return this.getOrganization().pipe(
      tap(organization => {
        this.store.dispatch(loginSuccess({ organization }));
        // Also dispatch check onboarding status
        this.store.dispatch(checkOnboardingStatus());
      }),
      map(organization => organization)
    );
  }

  setTokens(accessToken: string, refreshToken: string): void {
    if (accessToken) {
      this.setAccessToken(accessToken);
    }

    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }
  }

  private setAccessToken(token: string): void {
    if (!token) {
      console.error('Attempted to set empty access token');
      return;
    }

    try {
      this.cookieService.set(
        this.ACCESS_TOKEN,
        token,
        {
          path: environment.cookie.path,
          domain: environment.cookie.origin,
          secure: environment.cookie.secure,
          sameSite: environment.cookie.sameSite as 'Strict' | 'Lax' | 'None',
          expires: this.convertMaxAgeToExpires(this.ACCESS_TOKEN_EXPIRY),
        }
      );
    } catch (error) {
      console.error('Error setting access token cookie:', error);
      this.performLogout();
    }
  }

  private setRefreshToken(token: string): void {
    if (!token) {
      console.error('Attempted to set empty refresh token');
      return;
    }

    try {
      this.cookieService.set(
        this.REFRESH_TOKEN,
        token,
        {
          path: environment.cookie.path,
          domain: environment.cookie.origin,
          secure: environment.cookie.secure,
          sameSite: environment.cookie.sameSite as 'Strict' | 'Lax' | 'None',
          expires: this.convertMaxAgeToExpires(this.REFRESH_TOKEN_EXPIRY),
        }
      );
    } catch (error) {
      console.error('Error setting refresh token cookie:', error);
      throw error;
    }
  }  

  private convertMaxAgeToExpires(maxAgeInSeconds: number): Date {
    const expiresDate = new Date();
    expiresDate.setSeconds(expiresDate.getSeconds() + maxAgeInSeconds);
    return expiresDate;
  }

  getOnboardingStatus(): Observable<OnboardingStatus> {
    return this.http.get<OnboardingStatus>(`${this.baseUrl}/users/onboarding-status`);
  }

  requestReset(email: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/auth/password-reset-request`, { email }, {
      context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)
    });
  }

  verifyToken(token: string): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/auth/password-reset-verify`, {
      params: { token },
      context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)
    });
  }

  resetPassword(token: string, password: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/auth/password-reset`, {
      token,
      password
    }, {
      context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)
    });
  }

  performLogout(): void {
    this.store.dispatch(logout());
    this.cookieService.delete(this.ACCESS_TOKEN, environment.cookie.path, environment.cookie.origin);
    this.cookieService.delete(this.REFRESH_TOKEN, environment.cookie.path, environment.cookie.origin);
    this.cookieService.delete('X-AFFILIATE', environment.cookie.path, environment.cookie.origin);
    void this.router.navigate(['/login']);
  }

  changePassword(data: { currentPassword: string; newPassword: string }): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/auth/password-change`, data, {
      context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)});
  }

  refreshToken(refreshToken?: string): Observable<TokenResponse> {
    // Track that we're refreshing
    this.isRefreshing = true;

    // Get the refresh token if not provided
    if (!refreshToken) {
      refreshToken = this.cookieService.get(this.REFRESH_TOKEN);
    }
    
    // Only check if token exists, not if it's valid (to avoid race conditions)
    if (!refreshToken) {
      this.isRefreshing = false;
      return throwError(() => new Error('No refresh token available'));
    }
  
    return this.http.post<TokenResponse>(
      `${this.baseUrl}/auth/refresh-token`,
      {},
      {
        headers: new HttpHeaders().set('Authorization', `Bearer ${refreshToken}`),
        context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)
      }
    ).pipe(
      tap(response => {
        console.debug('Token refresh succeeded, setting new tokens');
        
        // IMPORTANT: Set the tokens before marking refresh as complete
        if (response.access_token) {
          this.setAccessToken(response.access_token);
        }
        if (response.refresh_token) {
          this.setRefreshToken(response.refresh_token);
        }
      }),
      catchError(error => {
        console.error('Token refresh failed:', error);
        
        // Don't immediately logout on all errors - only if it's a 401/403 error
        if (error.status === 401 || error.status === 403) {
          this.performLogout();
        }
        
        return throwError(() => error);
      }),
      // Always reset refreshing flag
      finalize(() => {
        this.isRefreshing = false;
      })
    );
  }

  private isTokenValid(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const expectedIssuer = 'https://nevent.es';
      
      return (
        decoded.iss === expectedIssuer && 
        (decoded.exp * 1000) > (Date.now() + (this.TOKEN_REFRESH_GRACE_PERIOD * 1000))
      );
    } catch {
      return false;
    }
  }

}
