import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { OnboardingGuard } from './onboarding/guards/onboarding.guard';
import { PreventOnboardingExitGuard } from './onboarding/guards/prevent-onboarding-exit.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  // Protected routes that require both authentication and completed onboarding
  {
    path: '',
    canActivate: [AuthGuard, OnboardingGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'events',
        loadChildren: () => import('./event/event.module').then(m => m.EventModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'lists',
        loadChildren: () => import('./list/list.module').then(m => m.ListModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./merch/merch.module').then(m => m.MerchModule),
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule),
          }
        ]
      },
      {
        path: 'web',
        loadChildren: () => import('./web/web.module').then(m => m.WebModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'goals',
        loadChildren: () => import('./action-types/action-types.module').then(m => m.ActionTypesModule),
      },
      {
        path: 'templates',
        loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  // Ruta para el onboarding
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    canDeactivate: [PreventOnboardingExitGuard]
  },
  // Redirección condicional en la ruta principal
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
