import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user-routing.module';
import { CoreModule } from '../core/core.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserHistoryComponent } from './components/user-history/user-history.component';
import { PurchaseDetailComponent } from './components/purchase-detail/purchase-detail.component';
import { PurchaseDetailTicketsComponent } from './components/purchase-detail-tickets/purchase-detail-tickets.component';
import { PurchaseDetailProductsComponent } from './components/purchase-detail-products/purchase-detail-products.component';
import { PurchaseDetailProductItemComponent } from './components/purchase-detail-product-item/purchase-detail-product-item.component';
import { PurchaseDetailTicketItemComponent } from './components/purchase-detail-ticket-item/purchase-detail-ticket-item.component';
import { PurchaseDetailExperienceItemComponent } from './components/purchase-detail-experience-item/purchase-detail-experience-item.component';
import { TokenHistoryComponent } from './components/token-history/token-history.component';
import { TokenDetailComponent } from './components/token-detail/token-detail.component';
import { ExpenseDialogComponent } from './components/expense-dialog/expense-dialog.component';
import { PublicProfileComponent } from './components/public-profile/public-profile.component';
import { PurchaseHistoryItemComponent } from './components/purchase-history-item/purchase-history-item.component';
import { InteractionHistoryComponent } from './components/interaction-history/interaction-history.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorModule } from 'primeng/editor';
import { ToastModule } from 'primeng/toast';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '../shared/shared.module';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { SpeedDialModule } from 'primeng/speeddial';
import { SkeletonModule } from 'primeng/skeleton';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';

@NgModule({
  declarations: [
    UserComponent,
    ImportDialogComponent,
    UserDetailComponent,
    UserHistoryComponent,
    PurchaseDetailComponent,
    PurchaseDetailTicketsComponent,
    PurchaseDetailProductsComponent,
    PurchaseDetailProductItemComponent,
    PurchaseDetailTicketItemComponent,
    PurchaseDetailExperienceItemComponent,
    TokenHistoryComponent,
    TokenDetailComponent,
    ExpenseDialogComponent,
    PublicProfileComponent,
    PurchaseHistoryItemComponent,
    InteractionHistoryComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    TableModule,
    CoreModule,
    ButtonModule,
    DialogModule,
    FileUploadModule,
    ReactiveFormsModule,
    TagModule,
    TooltipModule,
    TimelineModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    InputNumberModule,
    EditorModule,
    ToastModule,
    QRCodeModule,
    SharedModule,
    CheckboxModule,
    MenuModule,
    SpeedDialModule,
    SkeletonModule,
    ChipsModule,
    ChipModule
  ]
})
export class UserModule { }
