import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from '../../service/user.service';
import { TokenPreview } from '../../model/token-preview.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-token-history',
  templateUrl: './token-history.component.html',
  styleUrls: ['./token-history.component.scss']
})
export class TokenHistoryComponent implements OnInit, OnChanges {

  @Input() userId: string;
  tokens: TokenPreview[] = [];
  selectedToken!: TokenPreview;
  loading: boolean = true;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    if (this.userId) {
      this.loadTokens();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && changes['userId'].currentValue) {
      this.loadTokens();
    }
  }

  loadTokens(): void {
    this.loading = true;
    this.userService.getTokenList(this.userId).subscribe(
      (response: TokenPreview[]) => {
        this.tokens = response;
        this.loading = false;
      },
      error => {
        console.error('Error loading tokens:', error);
        this.loading = false;
      }
    );
  }

  openToken($event: any) {
    this.router.navigate(['/users', this.userId, 'tokens', $event.data.id]);
  }

  clear(table: any): void {
    table.clear();
  }
}
