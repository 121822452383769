import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EventComponent } from './event.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { EventDetailAttendeesComponent } from './components/event-detail-attendees/event-detail-attendees.component';
import { EventDetailDescriptionComponent } from './components/event-detail-description/event-detail-description.component';
import { EventDetailVenueComponent } from './components/event-detail-venue/event-detail-venue.component';
import { EventDetailTicketsComponent } from './components/event-detail-tickets/event-detail-tickets.component';
import { EventDetailExperiencesComponent } from './components/event-detail-experiences/event-detail-experiences.component';
import { EventDetailContentComponent } from './components/event-detail-content/event-detail-content.component';
import { EventDetailSalesComponent } from './components/event-detail-sales/event-detail-sales.component';
import { EventDetailSponsorsComponent } from './components/event-detail-sponsors/event-detail-sponsors.component';
import { EventDetailMessagesComponent } from './components/event-detail-messages/event-detail-messages.component';
import { EventDetailSummaryComponent } from './components/event-detail-summary/event-detail-summary.component';
import { EventDetailStreamingComponent } from './components/event-detail/event-detail-streaming/event-detail-streaming.component';
import { EventDetailMomentsComponent } from './components/event-detail/event-detail-moments/event-detail-moments.component';
import { EventDetailCouponsComponent } from './components/event-detail/event-detail-coupons/event-detail-coupons.component';
import { EventDetailReassignationComponent } from './components/event-detail/event-detail-reassignation/event-detail-reassignation.component';

const routes: Routes = [
  {
    path: '', component: EventComponent,
  },
  {
    path: 'create', component: CreateEventComponent,
  },
  {
    path: ':id', component: EventDetailComponent,
    children: [
      {
        path: 'summary', component: EventDetailSummaryComponent,
      },
      {
        path: 'description', component: EventDetailDescriptionComponent,
      },
      {
        path: 'venue', component: EventDetailVenueComponent,
      },
      {
        path: 'tickets', component: EventDetailTicketsComponent,
      },
      {
        path: 'experiences', component: EventDetailExperiencesComponent,
      },
      {
        path: 'sponsors', component: EventDetailSponsorsComponent,
      },
      {
        path: 'sales', component: EventDetailSalesComponent,
      },
      {
        path: 'attendees', component: EventDetailAttendeesComponent,
      },
      {
        path: 'messages', component: EventDetailMessagesComponent,
      },
      {
        path: 'content', component: EventDetailContentComponent,
      },
      {
        path: 'public-relations', component: EventDetailContentComponent,
      },
      {
        path: 'streaming', component: EventDetailStreamingComponent,
      },
      {
        path: 'moments', component: EventDetailMomentsComponent,
      },
      {
        path: 'coupons', component: EventDetailCouponsComponent,
      },
      {
        path: 'reassignation', component: EventDetailReassignationComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule {
}
