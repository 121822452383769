import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OnboardingContainerComponent } from '../components/onboarding-container/onboarding-container.component';
import { OnboardingService } from '../services/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class PreventOnboardingExitGuard implements CanDeactivate<OnboardingContainerComponent> {
  
  constructor(
    private onboardingService: OnboardingService,
    private router: Router
  ) {}
  
  canDeactivate(
    component: OnboardingContainerComponent,
    currentRoute: any,
    currentState: any,
    nextState?: any
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Si el componente o la sesión no existen, permitir la salida
    if (!component || !component.session) {
      return true;
    }
    
    // Simular que el onboarding está completo
    if (component.session) {
      component.session.completed = true;
    }
    
    // Si el onboarding está completo, permitir la salida
    if (component.session.completed) {
      return true;
    }
    
    // Si la navegación es entre pasos del onboarding, permitirla
    if (nextState && nextState.url.includes('/onboarding')) {
      return true;
    }
    
    // Si la navegación es hacia la página de inicio, permitirla
    if (nextState && (nextState.url === '/' || nextState.url === '/home')) {
      return true;
    }
    
    // Si no está completo y se intenta salir del onboarding, impedir la salida
    return false;
  }
} 