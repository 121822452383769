import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Purchase, PurchaseState } from '../../model/purchase.interface';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent implements OnInit {
  @Input() set purchases(value: Observable<Purchase[]>) {
    this.loading = true;
    value.subscribe(purchases => {
      this.purchaseList = purchases;
      this.loading = false;
    });
  }

  purchaseList: Purchase[] = [];
  loading: boolean = true;

  // Mapa de proveedores
  private providerMap: { [key: string]: string } = {
    '67af6faf2215c27187e557af': 'Enterticket',
    // Añadir más proveedores según sea necesario
  };

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Suscribirse al observable de compras
    this.purchases.subscribe(data => {
      this.purchaseList = data;
    });
  }

  // Método para formatear la fecha
  formatDate(date: string | Date): string {
    return new Date(date).toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  // Método para formatear el importe (en céntimos)
  formatAmount(amount: number): string {
    // Convertir de céntimos a euros
    const euros = amount / 100;
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR'
    }).format(euros);
  }

  // Método para obtener el estado traducido
  getStateLabel(state: PurchaseState): string {
    const states = {
      [PurchaseState.PENDING]: 'Pendiente',
      [PurchaseState.COMPLETE]: 'Completado',
      [PurchaseState.FAILED]: 'Fallido'
    };
    return states[state] || state;
  }

  // Método para obtener la clase CSS según el estado
  getStateClass(state: PurchaseState): string {
    const classes = {
      [PurchaseState.PENDING]: 'status-pending',
      [PurchaseState.COMPLETE]: 'status-completed',
      [PurchaseState.FAILED]: 'status-failed'
    };
    return classes[state] || '';
  }

  // Método para obtener el canal de compra basado en el providerId
  getChannel(purchase: Purchase): string {
    return this.providerMap[purchase.providerId] || 'No especificado';
  }

  // Método para obtener el nombre de la experiencia
  getExperienceName(purchase: Purchase): string {
    return purchase.eventName || 'No especificado';
  }

  // Método para navegar al detalle de la compra
  onRowClick(purchase: Purchase): void {
    this.router.navigate(['/purchases', purchase.id]);
  }
}
