export interface Purchase {
    id: string;
    eventId: string;
    eventName: string;
    state: PurchaseState;
    providerId: string;
    providerPurchaseId: string;
    providerTicketId: string;
    userId: string;
    purchasedBy: string;
    purchasedAt: Date;
    createdAt: Date;
    modifiedAt: Date;
    createdBy: string;
    modifiedBy: string;
    deletedAt: Date | null;

    // Campos de precios
    currency: string;
    totalPrice: number;
    totalBasePrice: number;
    totalDiscount: number;
    totalFee: number;
    totalVat: number;
    totalItems: number;
    deliveryFee: number;
    offlineAmount: number;
    originalTotalPrice: number;

    // Campos relacionados con el pago
    paymentMethod: string | null;
    stripePaymentIntentId: string | null;
    stripePaymentIntentClientSecret: string | null;
    stripeConnectAccountId: string | null;

    // Campos relacionados con promociones
    affiliateCode: string | null;
    campaignId: string | null;
    couponCode: string | null;
    couponUsages: number;
    balance: number | null;

    // Campos relacionados con el contenido
    movements: Movement[];
    tickets: Ticket[];
    ticketOwners: string[];
    products: any[] | null;
    experiences: any[];
    ticketExperienceLink: any[];
    delivery: any | null;

    // Otros campos
    lang: string | null;
    sessionId: string | null;
    tenantId: string;
    ticketId: string;
}

export interface Movement {
    id: string | null;
    description: string;
    type: string;
    unitPrice: number;
    quantity: number;
}

export interface Ticket {
    ticketId: string;
    ticketTypeId: string;
}

export enum PurchaseState {
    PENDING = "PENDING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    COMPLETE = "COMPLETE"
}